
.PricingTableWrapper {
    display: flex;
    justify-content: space-evenly;
    font-family: 'Lato', sans-serif;
    /*text-align: center;*/
    margin: 20px auto;
    max-width: 1200px;
}



.PricingTableWrapper h2, h3 {
    text-align: center;
}

.PricingTable h5, .PricingTableSelected h5 {
    text-align: left;
    /*margin-left: 50%;*/
    margin-left: 25%;
    padding:5px
}


.FeaturedImage {
    box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);
}
.HostingAndMaintenance{
    font-size: 14px;
}
.PricingTable {
    /*display: inline-block;*/
    /*border: 1px solid #C8C8C8;*/
    width: 300px;
    max-width: 300px;
    border-radius: 20px;
    /*background: #EBF7F9;*/
    margin: 20px;
    transition: all 0.3s ease-in-out;
    /*border: 4px solid groove;*/
    box-shadow: 5px 5px 10px 5px #262626;
    background-color: #d8eff3;

}

.PricingTableSelected {
    text-align: center;
    width: 300px;
    max-width: 300px;
    border-radius: 20px;
    /*background: white;*/
    margin: 20px;
    transition: all 0.3s ease-in-out;
    /*border: 4px solid groove;*/
    box-shadow: 2px 2px 30px 15px #29D9C2;
    background-color: #EBF7F9;

}

.PricingTable__header {
    padding: 20px;
    font-size: 20px;
    color: white;
    background: #29D9C2;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}


.PricingTable__price {
    color: #66CCCC;
    padding: 20px;
    margin: auto;
    font-size: 40px;
    font-weight: 500;
}


.PricingTable__button {
    display: block;
    background: #66CCCC;
    text-decoration: none;
    padding: 20px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

}

.PricingTable__button:before {
    position: absolute;
    left: -20%;
    top: -10%;
    content: '';
    width: 60%;
    height: 220%;
    transform: rotate(-30deg);
    background: white;
    opacity: .3;
    transition: all 0.3s ease-in-out;
}


.PricingTable__button:after {
    /*position: absolute;*/
    /*!*content: '>';*!*/
    /*top: 0;*/
    /*right: 0;*/
    /*font-size: 25px;*/
    /*padding: 15px;*/
    /*padding-right: 40px;*/
    /*color: white;*/
    /*opacity: 0;*/
    /*transition: all 0.3s ease-in-out;*/
}


/*.PricingTable__button:hover {*/
/*    background: black;*/
/*}*/

.PricingTableWrapper p {
    text-align: left;
    margin-left: 30%;
}

li{
    list-style: none;
}
.PricingTable__list {
    padding: 10px 20px;
    color: #A0A0A0;
}
.List:before {
    content:"\2713\0020";
    font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}

.PricingTable__list li {
    padding: 15px;
    border-bottom: 1px solid #C8C8C8;
}

.PricingTable__list li:last-child {
    border: none;
}


.PricingTable:hover {
    box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);
}


/*or  .pricing-table__button:hover */
.PricingTable:hover .PricingTable__button {
    /*padding-left: 0;*/
    /*padding-right: 35px;*/

}

.PricingTable:hover .PricingTable__button:before {
    /*top: -80%;*/
    /*transform: rotate(0deg);*/
    /*width: 100%;*/
}


.PricingTable:hover .PricingTable__button:after {
    opacity: 1;
    /*padding-right: 15px;*/
}

.Pagination {
    /*display: block;*/
    display: flex;
    justify-content: space-between;
}

.Pagination button {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #EBF7F9;
    font-weight: bold;
    height: 40px;
    color: #262626;
}

.Pagination button:hover {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #D8EFF3;
    box-shadow: 1px 1px 1px #cdcde5;
    color: black;
}

