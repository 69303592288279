.Toolbar {
    width: 100%;
    background-color: rgba(0,0,0,.85);
}
.Width{
    margin: auto;
    max-width: 1200px;
    display: flex;
    justify-content: normal;
    align-items: center;
    height: 80px;


}
.Logo{
    width: 30%;
    display: flex;
}
