.BackgroundVideo{
    z-index: -1;
    position: absolute;

}
.BackgroundVideo img {
    width: 1900px;
    height: 700px;
    filter: blur(10px);
    -webkit-filter: blur(10px);
}