.Main{
    max-width: 1200px;
    width: 100%;
    margin :10px auto;
}
.Layouts {
    /*background-color: #E8E8F3;*/
}
/*@media only screen and (min-width: 800px) {*/
/*  set default width and small for min 800*/
/*}*/