.Site {
    max-width: 1200px;
    margin: 10px auto;
}

.Site h2 {
    text-align: center;
}

.Site form {
    display: flex;
    justify-content: space-between;
    margin: 80px auto;
}

.Site p {
    text-align: center;
    font-weight: bold;
}

.LabelDiv div {
    text-align: center;
}

.SiteSelectedDefault {
    /*border: 4px solid groove;*/
    box-shadow: 0 2px 3px darkgreen;
    box-shadow: 5px 5px 10px 5px #262626;
    border-radius: 15px;
    padding: 20px;
    background-color: #EBF7F9;
}

.SiteSelected {
    /*border: 4px solid groove;*/
    box-shadow: 0 2px 3px darkgreen;
    box-shadow: 2px 2px 30px 15px #29D9C2;
    border-radius: 15px;
    padding: 20px;
    background-color: #EBF7F9;

}

.Site form img {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.Pagination {
    display: flex;
    justify-content: space-between;
}

.Site button {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #EBF7F9;
    font-weight: bold;
    height: 40px;
    color: #262626;
}

.Site button:hover {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #D8EFF3;
    box-shadow: 1px 1px 1px #cdcde5;
    color: black;
}
/*.LabelDiv input {*/
/*    display: none;*/
/*}*/
/*.LabelDiv label {*/
/*    !*text-transform: uppercase;*!*/
/*    font-weight: bold;*/
/*}*/

/*.Site [input] {*/
/*    display: block;*/
/*    transform: scale(1.5);*/
/*    margin: 0 auto;*/
/*}*/