.Language img{
    width: 150px;
    height: 150px;
}
.Language h2{
    text-align: center;
}
.Language p,input{
   text-align: center;
   font-weight: bold;
}
.QustionMark{
    /* margin-top: 20px; */
}
.QustionMark img{
    /* width: 170px;
    height: 170px; */
}
.QuestionMark input{

   display: block;
   margin:  auto;
}
.Language form {
    display: flex;
    justify-content: space-between;
    margin: 80px auto;
}
.NotSelected{
    box-shadow: 0 2px 3px darkgreen;
    box-shadow: 5px 5px 10px 5px #262626;
    border-radius: 15px;
    padding: 20px;
    background-color: #EBF7F9;

}
.Selected{
    box-shadow: 0 2px 3px darkgreen;
    box-shadow: 2px 2px 30px 15px #29D9C2;
    border-radius: 15px;
    padding: 20px;
    background-color: #EBF7F9;

    /*width:260px;*/
    /*height:250px ;*/
}
.Pagination{
    display: flex;
    justify-content: space-between;
}
.Language button {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #EBF7F9;
    font-weight: bold;
    height: 40px;
    color: #262626;
}

.Language button:hover {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #D8EFF3;
    box-shadow: 1px 1px 1px #cdcde5;
    color: black;
}