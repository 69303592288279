.Input p {
    color: red;
}

label {
    font-size: 18px;
    font-weight: bold;
}

.Input p {
    color: red;
}

.Input label span {
    color: darkred;
}

/*.Input input {*/
/*    background-color: #EBF7F9;*/
/*    font-weight: 500;*/
/*}*/

