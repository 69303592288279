/*.Comment {*/
/*    margin: 150px auto;*/
/*}*/
.Comment h2 {
    text-align: center;
}

.Text {
    /*display: block;*/
    margin: 150px auto;
    text-align: center;
}
.Text textarea {
    background-color: #EBF7F9;
    border-radius: 8px;
    border: 2px solid #cdcde5;
}
.Text textarea:focus {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    box-shadow: 0 6px 8px #cdcde5;
    outline: none;
}

