.Footer {
    width: 100%;
    background-color: #121212;
    height: 250px;
    max-height: 350px;
    bottom: 0;
    /* position: absolute; */

}
.Footer main {
    width: 100%;
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    /*text-align: center;*/
    margin: 10px auto;
    color: white;
}