.ContactData {
    max-width: 1200px;
    margin: 20px auto;
}

.ContactData h2 {
    text-align: center;
}

.ContactData form {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin: 40px auto;
}

.ContactData input {
    width: 500px;
    display: block;
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #EBF7F9;
    font-weight: 500;

}

.ContactData input:focus {
    /*border: 3px solid #EBF7F9*/
    border-radius: 8px;
    border: 2px solid #cdcde5;
    box-shadow: 0 3px 3px #cdcde5;
    outline: none;
}

.ContactData label {
    text-align: left;
    margin: 1px auto;
    padding-top: 20px;
}

.Pagination {
    display: flex;
    justify-content: flex-end;
}

.ContactData button {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #EBF7F9;
    font-weight: bold;
    height: 40px;
    color: #262626;
}

.ContactData button:hover {
    border-radius: 8px;
    border: 2px solid #cdcde5;
    background-color: #D8EFF3;
    box-shadow: 1px 1px 1px #cdcde5;
    color: black;
}
